import { graphql, Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/services.scss";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import CommonSection from "../components/CommonSection";
import ButtonBanner from "../components/ButtonBanner";
import StatColumn from "../components/StatColumn";
import ClientLogoBanner from "../components/ClientLogoBanner";
import AccordionSection from "../components/AccordionSection";
import CaseStudy from "../sections/homepage/CaseStudy";
import Blogs from "../sections/homepage/Blogs";
import Testimonials from "../sections/homepage/Testimonials";

const InteractiveProductCatalog = ({ data }) => {
  const benefits = [
    {
      image: "../images/3dproductcatalog/augmented-reality-software.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-software.webp",
      title: "In-house team of expert 3D artists",
      sub: "We have a team of 3D artists and freelancers. We can create 3D renders at speed and with flexibility.",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-software.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-software.webp",
      title: "No code proprietary interactive software",
      sub: "Our proprietary software will create product features walkthrough in minutes.",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-software.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-software.webp",
      title: "Lightning fast speed",
      sub: "Bulk upload and edit images with AI driven software on a cloud storage. We ensure high website speed.",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-software.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-software.webp",
      title: "Images & 3D renders can be downloaded for distribution",
      sub: "Distribute 3D images in as many marketplaces as you desire.",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-software.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-software.webp",
      title: "24/7 Support",
      sub: " We handhold you and give access to DIY interactive software for complete freedom.",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-software.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-software.webp",
      title: "Get 3D models on your website in minutes",
      sub: "Our software is easy to integrate and sets you up in minutes. You can choose to install code just like Google Analytics, or can opt for API or SDK based integrations.",
    },
  ];
  const list = [
    {
      image: "../images/3dproductcatalog/augmented-reality-platform.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-platform.webp",
      title: "End to end product shoot",
      sub: "If you do not have the image gallery, worry not! we will take care of the shoot and get you ready for the new-gen visual commerce",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-platform.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-platform.webp",
      title: "3D creation & rendering of images",
      sub: "You could also choose to send your products to us and we can create 3D scale models and renders and get you live in minutes.",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-platform.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-platform.webp",
      title: "Create product interactions",
      sub: "Showcase your products inside out, and how it operates within seconds to your users.",
    },
    {
      image: "../images/3dproductcatalog/augmented-reality-platform.png",
      imageWebp: "../images/3dproductcatalog/augmented-reality-platform.webp",
      title: "Realtime product feature walkthrough",
      sub: "Now highlight the USPs and important details of the products to your users interactively.",
    },
  ];

  return (
    <Layout>
      <Helmet>
        <title>
          HelloAR: 3D visualization software| Augmented Reality Software
        </title>
        <meta
          name="description"
          content="Our 3D visualization software, augmented reality software, and virtual reality software can help increase conversion by 40 percent"
        />
        <meta
          name="keywords"
          content="3D visualization software, augmented reality software, and virtual reality software"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta
          property="og:image"
          content="../../videos/thumbnail/configurator.jpg"
        />
      </Helmet>

      <Container fluid className="services">
        <div className="spacer"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <CommonSection
          title="Bring your products to life"
          sub="Multiply your revenues by converting your 2D images into Interactive 3D Visual images in minutes"
          buttonText="BOOK A DEMO"
        >
          <VideoPlayer
            videoUrl="../../videos/ar-solution.mp4"
            videoThumbnail="../../images/thumbnail/interactive.webp"
          />
        </CommonSection>
        <div className="spacer-sm"></div>
        <ClientLogoBanner />
        <div className="spacer"></div>
        <AccordionSection
          items={list}
          title="Our one stop solution offers"
          mainIndex={3}
        />
        <div className="spacer"></div>
        <StatColumn
          title="Companies with interactive 3D catalogs have"
          items={[
            {
              stat: "250%",
              info: "Upto 250% higher conversions",
            },
            {
              stat: "11x",
              info: "Upto 11x more purchases",
            },
            {
              stat: "35%",
              info: "Upto 35% fewer product returns",
            },
          ]}
          noFlex
        />
        <div className="spacer"></div>
        {/* <CaseStudy /> */}
        <div className="spacer"></div>
        <ButtonBanner buttonTitle="REQUEST A DEMO" buttonUrl="/contact-us">
          <h1 className="h1 text-center text-light">
            One stop solution.
            <br />
            Zero upfront cost. Pay as you go.
          </h1>
        </ButtonBanner>
        <div className="spacer"></div>
        <AccordionSection
          items={benefits}
          title="Other benefits of HelloAR"
          mainIndex={3}
        />
        <div className="spacer"></div>
        <Testimonials />
        {/* <div className="spacer"></div> */}
        {/* <Blogs /> */}
        {/* <div className="spacer"></div> */}
      </Container>
    </Layout>
  );
};

// export const imgs = graphql`
//   query {
//     imageOne: file(relativePath: { eq: "4x3placeholder.png" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;

export default InteractiveProductCatalog;
